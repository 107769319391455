import { useState, useEffect, useRef } from 'react';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import axios from 'axios'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'

import { ParallaxProvider, useParallax, Parallax, ParallaxBanner } from 'react-scroll-parallax';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import "./styles.css";
import 'animate.css';
import './App.css';

import location from './img/location.png'
import bed from './img/bed.png'
import bath from './img/bath.png'
import build from './img/build.png'
import parking from './img/parking.png'

import { Envelope, Facebook, Filter, Instagram, Linkedin, TelephoneFill, Tiktok, Whatsapp } from 'react-bootstrap-icons';

const EASY_PATH = "http://crstl.com.mx/srv/properties.do";

function Properties() {
  const [ properties, setProperties ] = useState([])
  const [ original, setOriginal ] = useState([])

  useEffect(() => {
    handleOnSubmit()
  }, []);

  const handleOnSubmit = () => {
    axios.post(EASY_PATH, {}, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'aW5tb2JpQW5kbWlu',
          'Access-Control-Allow-Origin': '*'
        }
      }).then(function(response) {
        setProperties(response.data.content)
        setOriginal(response.data.content)
        console.log("success", response.data.content)
      }).catch(function(err) {
        console.log("error", err)
      });
  }

  const handleOnDetail = (id) => {
    axios.post(EASY_PATH + "?pid=" + id, {}, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'aW5tb2JpQW5kbWlu',
          'Access-Control-Allow-Origin': '*'
        }
      }).then(function(response) {
        if(response.data) {
          window.open(response.data.public_url, '_blank');
        }
      }).catch(function(err) {
        console.log("error", err)
      });
  }

  const handleOnChangeCheck = (e) => {
    var ctrl = document.getElementById('filters').getElementsByTagName('input');
    var result = original
    
    var i=0, c;
    while( c = ctrl[i++] ){
      console.log('type', c.type, c.id, c.checked)
      if(c.type=='checkbox'){
        if(c.id == 'home' && c.checked)
          result = result.filter(function(item) { return item.property_type === 'Casa'; });
        if(c.id == 'depto' && c.checked)
          result = result.filter(function(item) { return item.property_type === 'Departamento'; });

        if(c.id == 'miguel' && c.checked)
          result = result.filter(function(item) { return item.location.includes('Allende'); });
        if(c.id == 'morelos' && c.checked)
          result = result.filter(function(item) { return item.location.includes('Morelos'); });
        if(c.id == 'edomex' && c.checked)
          result = result.filter(function(item) { return item.location.includes('Estado'); });

        if(c.id == 'venta' && c.checked)
          result = result.filter(function(item) { return item.operations[0].type === 'sale'; });
        if(c.id == 'renta' && c.checked)
          result = result.filter(function(item) { return item.operations[0].type === 'rental'; });
      }
    }

    setProperties(result)
  }

  return (
    <div>
      <div className="section-service">
        <Container className="section">
          <br/>
          <Row className="service-cards-container">
            <Col md={ 2 } id='filters'>
              { !isMobile && 
                <>
                  <br/>
                  <br/>
                  <br/>
                </>
              }
              <div className="filter-btn">
                <Filter className="contact-icon" />&nbsp;&nbsp;&nbsp;
                <span className='filter-btn-text'>Filtros</span>
              </div>
              <br/>
              <div className="filter-title">TIPO DE PROPIEDAD</div>
              <br/>
              <Form.Group className="mb-2">
                <Form.Check type="checkbox" label="Casa" id='home' onChange={ handleOnChangeCheck }/>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Check type="checkbox" label="Departamento" id='depto' onChange={ handleOnChangeCheck }/>
              </Form.Group>
              <hr className='filter-hr'/>
              <div className="filter-title">UBICACIÓN</div>
              <br/>
              <Form.Group className="mb-2">
                <Form.Check type="checkbox" label="San Miguel de A." id='miguel' onChange={ handleOnChangeCheck }/>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Check type="checkbox" label="Morelos" id='morelos' onChange={ handleOnChangeCheck }/>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Check type="checkbox" label="Edo. de Mex." id='edomex' onChange={ handleOnChangeCheck }/>
              </Form.Group>
              <hr className='filter-hr'/>
              <div className="filter-title">CATEGORÍA</div>
              <br/>
              <Form.Group className="mb-2">
                <Form.Check type="checkbox" label="Venta" id='venta' onChange={ handleOnChangeCheck }/>
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Check type="checkbox" label="Renta" id='renta' onChange={ handleOnChangeCheck }/>
              </Form.Group>
              <br/>
            </Col>
            <Col md={ 10 }>
              <div className="portfolio-title" style={ !isMobile ? { textAlign: 'left' } : { textAlign: 'center' }}>
                Propiedades disponibles
              </div>
              <br/>
              <Container>
                <Row>
                  { properties.map(item => (
                    <Col md={ 6 } xs={ 12 } key={ item.public_id } className="col-md-6-custom" style={{ cursor: 'pointer' }} onClick={ () => handleOnDetail(item.public_id) }>
                      <Card className="property-card">
                        <div className="property-card-image" style={{ backgroundImage: 'url(' + item.title_image_full + ')' }}></div>
                        <Card.Body style={{ position: 'relative' }}>
                          <div className="property-card-title">{ item.title }</div>
                          <div className="property-card-price">
                            { item.operations[0].formatted_amount + " " + item.operations[0].currency + " " }
                            {item.operations[0].unit == "square_meter" && 
                              <span>por m<sup>2</sup></span>
                            }
                          </div>
                          
                          <div style={{ paddingBottom: '15px' }}>
                            <img src={ location } className='property-card-icon' />&nbsp;&nbsp;
                            <span className="property-card-location">{ item.location }</span>
                          </div>

                          <div className="property-card-type">{ item.operations[0].type == 'sale' ? 'VENTA' : 'RENTA' }</div>
                          <br/>
                          <br/>
                          
                          <div style={{ position: 'absolute', bottom: '0', paddingBottom: '10px' }}>
                            <img src={ item.property_type == 'Casa' ? bed : parking } className='property-card-icon-small' />&nbsp;&nbsp;
                            <span className="property-card-small">{ item.property_type == 'Casa' ? ( item.bedrooms ? item.bedrooms : 'N/A' ) : ( item.parking_spaces ? item.parking_spaces : 'N/A' ) }</span>&nbsp;&nbsp;&nbsp;&nbsp;
                            <img src={ bath } className='property-card-icon-small' />&nbsp;&nbsp;
                            <span className="property-card-small">{ item.bathrooms ? item.bathrooms : 'N/A' }</span>&nbsp;&nbsp;&nbsp;&nbsp;
                            <img src={ build } className='property-card-icon-small' />&nbsp;&nbsp;
                            <span className="property-card-small">{ item.construction_size }m<sup>2</sup></span>&nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
      
    </div>
  );
}

export default Properties;
