import { useState, useEffect, useRef } from 'react';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import axios from 'axios'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'

import { ParallaxProvider, useParallax, Parallax, ParallaxBanner } from 'react-scroll-parallax';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import "./styles.css";
import 'animate.css';
import './App.css';

import contact from './img/contact.png'
import logo from './img/logo_inmobi.png'

import { Envelope, Facebook, GeoAltFill, Instagram, Linkedin, TelephoneFill, Tiktok, Whatsapp } from 'react-bootstrap-icons';

const API_PATH = 'https://inmobiplace.com/mailer.php';
const SITE_KEY = "6LcSmUgiAAAAAEfRTieNZ7hc8jcfhGayD9mxUKYX";

function Contact() {
  const [ activeForm, setActiveForm ] = useState(false)

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");

  const [showMessage, setShowMessage] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
 
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
 
      if (isScriptExist && callback) callback();
    }
 
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
      console.log("Script loaded!");
    });
  }, []);

  const handleOnClick = (e) => {
    e.preventDefault();
    setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        handleOnSubmit(token);
      });
    });
  }

  const handleOnSubmit = (token) => {
    var form = document.getElementById('contactForm');
    var data = new FormData(form);
    data.append('recaptcha_response', token);

    axios.post(API_PATH, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
    }).then(function(response) {

      if(response.data == "FAIL" || response.data == "ERROR") {
        setLoading(false)
        if(isMobile) setActiveForm(false)
        setTitle("Error")
        setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
        setShowMessage(true)
      } else {
        setLoading(false)
        if(isMobile) setActiveForm(false)
        setTitle("Mensaje enviado")
        setMessage("Tu mensaje ha sido enviado. En breve nos comunicaremos contigo. Gracias.");
        setShowMessage(true)

        setName("");
        setPhone("");
        setEmail("");
        setComments("");
        setShow(false);
      }

    }).catch(function(err) {
      setLoading(false)
      setTitle("Error")
      setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
      setShowMessage(true)
    });
  }

  return (
    <div>
      <Modal show={showMessage} onHide={ () => setShowMessage(false) }>
        <Modal.Header style={{ background: "#fff" }} closeButton>
          <Modal.Title style={{ color: "#000" }}>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000", background: "#fff" }}>{ message }</Modal.Body>
        <Modal.Footer style={{ color: "#000", background: "#fff" }}>
          <Button variant="secondary" onClick={() => setShowMessage(false) }>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="section-service">
        <Container className="section">
          <br/>
          <div className="portfolio-title" style={ !isMobile ? { textAlign: 'left' } : {} }>
            Estamos aquí para ayudarte, escríbenos tus dudas
          </div>
          <br/>
          <br/>
          <Row className="service-cards-container">
            { !isMobile && 
              <Col md={ 6 } className="form-col">
                <img src={ contact } className="header-img"/>
              </Col>
            }
            <Col md={ 6 } className="form-col">
              <div className="form-container">
                <br/>
                <Form id="contactForm" onSubmit={handleOnClick}>
                  <Form.Group>
                    <Form.Label className="label-custom">Nombre completo</Form.Label>
                    <Form.Control required name="name" value={name} onChange={ (e) => setName(e.target.value) } />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="label-custom">Correo Electrónico</Form.Label>
                    <Form.Control required type="email" value={email} name="email" onChange={ (e) => setEmail(e.target.value) } />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="label-custom">Teléfono</Form.Label>
                    <Form.Control required type="number" value={phone} name="phone" onChange={ (e) => setPhone(e.target.value) } />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="label-custom">Mensaje</Form.Label>
                    <Form.Control required name="comments" as="textarea" value={comments} rows="2" onChange={ (e) => setComments(e.target.value) } />
                  </Form.Group>

                  <div align="center">
                    <Button disabled={isLoading} variant="outline-primary" type="submit">{isLoading ? 'Enviando…' : 'ENVIAR'}</Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
          <Row className="contact-row-container">
            <Col md={ 6 } xs={ 12 } className="contact-col-container">
              <div className="contact-container" onClick={  () => window.open("https://wa.me/5215525985051")  }>
                <Whatsapp className="contact-icon" />&nbsp;&nbsp;&nbsp;
                <span className="contact-info">55 2598 5051</span>
              </div>
              <div className="contact-container" onClick={  () => window.open("tel:5528391785")  }>
                <TelephoneFill className="contact-icon" />&nbsp;&nbsp;&nbsp;
                <span className="contact-info">55 2839 1785</span>
              </div>
              <div className="contact-container" onClick={ () => window.open("mailto:contacto@inmobiplace.com") }>
                <Envelope className="contact-icon" />&nbsp;&nbsp;&nbsp;
                <span className="contact-info">contacto@inmobiplace.com</span>
              </div>
              <div className="contact-container" onClick={ () => window.open("https://g.page/agencia-mg-publicidad?share") }>
                <GeoAltFill className="contact-icon" />&nbsp;&nbsp;&nbsp;
                <span className="contact-info">Calle, José Guadalupe Posada #16, Cd. Satélite, 53100 Naucalpan de Juárez, Méx.</span>
              </div>
            </Col>
            
            <Col md={ 6 } xs={ 12 } className="contact-col-container" align="center">
              <img src={ logo } className="contact-logo"/>
            </Col>
          </Row>
        </Container>
      </div>
      
    </div>
  );
}

export default Contact;
