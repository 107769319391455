import { useState, useEffect, useRef } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { isMobile, isAndroid, isIOS } from 'react-device-detect';

import Home from "./Home";
import Services from "./Services";
import Portfolio from './Portfolio';
import Contact from './Contact';
import Properties from './Properties';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { Facebook, Instagram, Linkedin, Tiktok, Whatsapp } from 'react-bootstrap-icons';
import "./styles.css";

import logo_inmobi from './img/logo_c.png'
import inmobi from './img/logo.png'

import 'animate.css';
import './App.css';

function App() {
  const [ faceLink, setFaceLink ] = useState('https://www.facebook.com/inmobiplace/')
  const [ instaLink, setInstaLink ] = useState('https://www.instagram.com/Inmobiplace/')
  const [ tiktokLink, setTiktokLink ] = useState('https://www.tiktok.com/@inmobiplace')
  const [ linkedLink, setLinkedLink ] = useState('https://www.linkedin.com/company/inmobiplace/?viewAsMember=true')
  const [ sectionSelected, setSectionSelected ] = useState('/')

  const [ goingUp, setGoingUp ] = useState(false);
  const prevScrollY = useRef(0);

  useEffect(() => {
    /*if ( isMobile ) {
      if( isAndroid ) {
        setFaceLink('fb://page/111933257823824')
      }
      else if( isIOS ) {
        setFaceLink('fb://profile/111933257823824')
      }
    }*/

    console.log(window.location.pathname)
    setSectionSelected(window.location.pathname)
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;

      if(currentScrollY > 10)
        document.getElementById("rensa-navbar").className = "nav-bar-custom navbar navbar-dark navbar-expand-lg fixed-top";
      else
        document.getElementById("rensa-navbar").className = "nav-bar-custom-start navbar navbar-dark navbar-expand-lg fixed-top";
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);

  return (
    <div>
      <Navbar id="rensa-navbar" collapseOnSelect expand="lg" variant="dark" className="nav-bar-custom-start" fixed="top">
        <Container>
          <Navbar.Brand>
            <img alt="" id="brand_image" src={ inmobi } width="auto" height="30" className="d-inline-block align-top brand-image-start" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              <Nav.Link href="/">
                <div className={ sectionSelected === '/' ? "nav-link-custom nav-link-custom-selected" : "nav-link-custom" }>Inicio</div>
              </Nav.Link>
              <Nav.Link href="/services">
                <div className={ sectionSelected === '/services' ? "nav-link-custom nav-link-custom-selected" : "nav-link-custom" }>Servicios</div>
              </Nav.Link>
              <Nav.Link href="/portfolio">
                <div className={ sectionSelected === '/portfolio' ? "nav-link-custom nav-link-custom-selected" : "nav-link-custom" }>Portafolio</div>
              </Nav.Link>
              <Nav.Link href="/properties">
                <div className={ sectionSelected === '/properties' ? "nav-link-custom nav-link-custom-selected" : "nav-link-custom" }>Propiedades</div>
              </Nav.Link>
              <Nav.Link href="/contact">
                <div className={ sectionSelected === '/contact' ? "nav-link-custom nav-link-custom-selected" : "nav-link-custom" }>Contacto</div>
              </Nav.Link>
            </Nav>
            <Nav className="me-auto">
            </Nav>
            <Nav>
              <Nav.Link  onClick={ () =>  window.open('https://wa.me/5215625985051') }>
                <Whatsapp className="icons-style"/>&nbsp;&nbsp;
                <span className="nav-link-custom"> 56 2598 5051 </span>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Home /> } />
          <Route path="services" element={ <Services /> } />
          <Route path="portfolio" element={ <Portfolio /> } />
          <Route path="contact" element={ <Contact /> } />
          <Route path="properties" element={ <Properties /> } />
        </Routes>
      </BrowserRouter>

      <div className="footer full-container">
        <Container style={ !isMobile ? { width: "90%" } : { width: "100%" } }>
          <Row>
            <Col md={3} xs={12} align={ !isMobile ? "left" : "center"}>
              <img src={ logo_inmobi } className="footer_logo"/>
              <br/>
              <br/>
            </Col>
            <Col md={3} xs={12} align={ !isMobile ? "left" : "center"}>
              <div className='footer-links'>Lorem ipsum dolor sit</div>
              <div className='footer-links'>Lorem ipsum dolor sit</div>
              <div className='footer-links'>Lorem ipsum dolor sit</div>
            </Col>
            <Col md={3} xs={12} align={ !isMobile ? "left" : "center"}>
              <div className='footer-links'>Lorem ipsum dolor sit</div>
              <div className='footer-links'>Lorem ipsum dolor sit</div>
              <div className='footer-links'>Lorem ipsum dolor sit</div>
            </Col>
            <Col md={3} xs={12} align={ !isMobile ? "right" : "center"} style={ !isMobile ? { position: "relative" } : {} }>
              <div className="rrss-icons">
                <Instagram className='icons-style' onClick={  () => window.open(instaLink) }/>&nbsp;&nbsp;&nbsp;
                <Facebook className='icons-style' onClick={  () => window.open(faceLink) }/>&nbsp;&nbsp;&nbsp;
                <Tiktok className='icons-style' onClick={  () => window.open(tiktokLink) }/>&nbsp;&nbsp;&nbsp;
                <Linkedin className='icons-style' onClick={  () => window.open(linkedLink) }/>
              </div>
            </Col>
          </Row>
        </Container>        
      </div>
    </div>
  );
}

export default App;
