import cardon from './img/cardon.png'
import rensa from './img/rensa.png'
import sph from './img/sph.png'
import gen1 from './img/gen1.png'
import gen2 from './img/gen2.png'
import gen3 from './img/gen3.png'

import cardon_g1 from './img/cardon/gallery-1.png'
import cardon_g2 from './img/cardon/gallery-2.png'
import cardon_g3 from './img/cardon/gallery-3.png'
import cardon_g4 from './img/cardon/gallery-4.png'
import cardon_g5 from './img/cardon/gallery-5.png'
import cardon_g6 from './img/cardon/gallery-6.png'
import cardon_g7 from './img/cardon/gallery-7.png'
import cardon_g8 from './img/cardon/gallery-8.png'
import cardon_g9 from './img/cardon/gallery-9.png'

export const proyects = [
  {
    title: 'Residencial cardón',
    location: 'San Miguel de Allende, Gto.',
    img: cardon,
    gallery: {
      g1: cardon_g1,
      g2: cardon_g2,
      g3: cardon_g3,
      g4: cardon_g4,
      g5: cardon_g5,
      g6: cardon_g6,
      g7: cardon_g7,
      g8: cardon_g8,
      g9: cardon_g9
    }
  },
  {
    title: 'RENSA',
    location: 'San Miguel de Allende, Gto. \ Querétaro \ Edo. De Mex. \ CDMX',
    img: rensa,
    gallery: {
      g1: cardon_g1,
      g2: cardon_g2,
      g3: cardon_g3,
      g4: cardon_g4,
      g5: cardon_g5,
      g6: cardon_g6,
      g7: cardon_g7,
      g8: cardon_g8,
      g9: cardon_g9
    }
  },
  {
    title: 'SPH',
    location: 'Palo Alto, Qro.',
    img: sph,
    gallery: {
      g1: cardon_g1,
      g2: cardon_g2,
      g3: cardon_g3,
      g4: cardon_g4,
      g5: cardon_g5,
      g6: cardon_g6,
      g7: cardon_g7,
      g8: cardon_g8,
      g9: cardon_g9
    }
  },
  {
    title: 'Nombre',
    location: 'ubicación',
    img: gen1,
    gallery: {
      g1: cardon_g1,
      g2: cardon_g2,
      g3: cardon_g3,
      g4: cardon_g4,
      g5: cardon_g5,
      g6: cardon_g6,
      g7: cardon_g7,
      g8: cardon_g8,
      g9: cardon_g9
    }
  },
  {
    title: 'Nombre',
    location: 'ubicación',
    img: gen2,
    gallery: {
      g1: cardon_g1,
      g2: cardon_g2,
      g3: cardon_g3,
      g4: cardon_g4,
      g5: cardon_g5,
      g6: cardon_g6,
      g7: cardon_g7,
      g8: cardon_g8,
      g9: cardon_g9
    }
  },
  {
    title: 'Nombre',
    location: 'ubicación',
    img: gen3,
    gallery: {
      g1: cardon_g1,
      g2: cardon_g2,
      g3: cardon_g3,
      g4: cardon_g4,
      g5: cardon_g5,
      g6: cardon_g6,
      g7: cardon_g7,
      g8: cardon_g8,
      g9: cardon_g9
    }
  },
]