import { useState, useEffect, useRef } from 'react';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { AnimationOnScroll } from 'react-animation-on-scroll';

import "./styles.css";
import 'animate.css';
import './App.css';

import rombo from './img/rombo.png'

function Home() {
  return (
    <div>
      <div className="home-section">
        <Container>
          <Row className="align-items-center">
            <Col md={ 6 }>
              <div className="header-title">Somos una agencia de marketing y comercialización</div>
              <br/>
              <div className='header-desc'>Especializada en el mercado inmobiliario residencial, comercial e industrial.</div>
            </Col>
            <Col md={ 6 }>
            </Col>
          </Row>
        </Container>
      </div>

      {/*<AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn animateOnce" animateOut="animate__fadeOut">*/}
        <Container className="section">
          <Row className="align-items-center">
            <Col>            
              <div className="section-desc" align={ 'center' }>
                <div>
                  <span className="section-title">¿Qué hacemos?</span>
                </div>
                <br/>
                { !isMobile && <br/> }
                <div>Nos enfocamos en implementar estrategias de marketing para promover y comercializar proyectos inmobiliarios, además de identificar el tipo de uso de suelo del inmueble o la etapa de construcción en la que se encuentre para lograr el éxito en ventas.</div>
                <br/>
                <div>En Inmobiplace hacemos especial énfasis en construir relaciones estrechas con nuestros clientes durante todo el proceso del proyecto para ser la primera opción.</div>
              </div>
            </Col>
          </Row>
        </Container>
      {/*</AnimationOnScroll>*/}
          
      <div className="section-blue">
        <Container className="section">
          <Row>
            <Col md={ 5 }>
              <div className="section-title-a" align={ "right" }>¿Cómo lo hacemos?</div>
            </Col>
            <Col md={ 1 }>
            </Col>
            <Col md={ 5 }>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={ 5 } className="how-title">
              DESCUBRIENDO SUS NECESIDADES.
            </Col>
            { !isMobile &&
              <Col md={ 1 }>
                <div align="center">
                  <img src={ rombo } className="rombo-img" />
                </div>
              </Col>
            }
            <Col md={ 5 } className="how-desc">
              Satisfacemos las necesidades de nuestros clientes, ofreciendo el servicio que mejor se adapte al proyecto.
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={ 5 } className="how-title">
              CONSIDERACIÓN.
            </Col>
            { !isMobile &&
              <Col md={ 1 }>
                <div align="center">
                  <img src={ rombo } className="rombo-img" />
                </div>
              </Col>
            }
            <Col md={ 5 } className="how-desc">
              ​​Atraemos prospectos mediante estrategias publicitarias digitales logrando una conversión correcta.
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={ 5 } className="how-title">
              VENTA.
            </Col>
            { !isMobile &&
              <Col md={ 1 }>
                <div align="center">
                  <img src={ rombo } className="rombo-img" />
                </div>
              </Col>
            }
            <Col md={ 5 } className="how-desc">
              ​Creamos un plan a través del análisis de datos obtenidos, identificando clientes potenciales, reduciendo el proceso de compra y cerrando el proceso de venta.
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={ 5 } className="how-title">
              FIDELIZACIÓN.
            </Col>
            { !isMobile &&
              <Col md={ 1 }>
                <div align="center">
                  <img src={ rombo } className="rombo-img" />
                </div>
              </Col>
            }
            <Col md={ 5 } className="how-desc">
              Nos enfocamos en darle una experiencia única y resultados positivos al cliente para ser siempre la primera opción.
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={ 5 } className="how-title">
              PLATAFORMAS.
            </Col>
            { !isMobile &&
              <Col md={ 1 }>
                <div align="center">
                  <img src={ rombo } className="rombo-img" />
                </div>
              </Col>
            }
            <Col md={ 5 } className="how-desc">
              Las plataformas digitales son esenciales en todos los ámbitos, en Inmobiplace hacemos uso de las que más impacto tienen dentro del sector inmobiliario para llegar a tu público objetivo.
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Home;
