import { useState, useEffect, useRef } from 'react';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import { proyects } from './Projects'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import { ParallaxProvider, useParallax, Parallax, ParallaxBanner } from 'react-scroll-parallax';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import "./styles.css";
import 'animate.css';
import './App.css';

import arrowBack from './img/arrow-back.png'
import arrowLeft from './img/arrow-left.png'

function Portfolio() {
  const [ selected, setSelected ] = useState( null )

  const handleOnSelect = (value) => {
    setSelected(value)
  }

  const handleBack = () => {
    setSelected(null)
  }

  return (
    <div>

      { !selected &&
        <div className="section-service">
          <Container className="section">
            <br/>
            <div className="portfolio-title">
              Creamos proyectos únicos
            </div>
            <br/>
            <Row className="service-cards-container">
              { proyects.map(item => 
                <Col md={ 6 } className="col-md-6-custom" style={{ cursor: 'pointer' }} onClick={ () => setSelected(item)}>
                  <Card className="card-portfolio">
                    <Card.Img variant="top" src={ item.img } />
                    <Card.Body>
                      <Container>
                        <Row>
                          <Col md={ 10 }>
                            <div className="portfolio-card-title">
                              { item.title }
                            </div>
                            <div className="portfolio-card-desc">
                              { item.location }
                            </div>
                          </Col>
                          <Col md={ 2 } align="right">
                            <img src={ arrowBack } className="portfolio-icon"/>
                          </Col>
                        </Row>
                      </Container>
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Row>
          </Container>
        </div>
      }

      { selected && 
        <div className="section-service">
          <Container className="section">
            <Row>
              <Col md={ 1 }>
                <img src={ arrowLeft } className="portfolio-arrow" onClick={ () => setSelected(null)}/>
              </Col>
              <Col md={ 11 }>
                <Container>
                  <Row>
                    <Col md={ 3 } xs={ 12 }>
                      <div className="portfolio-detail-title">{ selected.title }</div>
                    </Col>
                    <Col md={ 9 } xs={ 12 } style={ isMobile ? {} : { position: 'relative' }}>
                      <div className="portfolio-detail-info">Investigación \ Branding \ Digital Media \ Preventa \ Comercialización de leads</div>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col className="portfolio-gall-container">
                      <img src={ selected.gallery.g1 } className="portfolio-gallery-img"/>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="portfolio-gall-container">
                      <div className="portfolio-detail-desc">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit.
                      </div>
                    </Col>
                    <Col className="portfolio-gall-container">
                      <div className="portfolio-detail-desc">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit.
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={ 8 } xs={ 12 } className="portfolio-gall-container col-md-6-custom">
                      <img src={ selected.gallery.g2 } className="portfolio-gallery-img"/>
                    </Col>
                    <Col md={ 4 } xs={ 12 } className="portfolio-gall-container col-md-6-custom">
                      <img src={ selected.gallery.g3 } className="portfolio-gallery-img"/>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={ 4 } xs={ 12 } className="portfolio-gall-container col-md-6-custom">
                      <img src={ selected.gallery.g4 } className="portfolio-gallery-img"/>
                    </Col>
                    <Col md={ 8 } xs={ 12 } className="portfolio-gall-container col-md-6-custom">
                      <img src={ selected.gallery.g5 } className="portfolio-gallery-img"/>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={ 12 } className="portfolio-gall-container">
                      <img src={ selected.gallery.g6 } className="portfolio-gallery-img"/>
                    </Col>
                    <Col md={ 12 } className="portfolio-gall-container">
                      <img src={ selected.gallery.g7 } className="portfolio-gallery-img"/>
                    </Col>
                    <Col md={ 12 } className="portfolio-gall-container">
                      <img src={ selected.gallery.g8 } className="portfolio-gallery-img"/>
                    </Col>
                    <Col md={ 12 } className="portfolio-gall-container">
                      <img src={ selected.gallery.g9 } className="portfolio-gallery-img"/>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="portfolio-gall-container">
                      <div className="portfolio-detail-desc">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit.
                      </div>
                    </Col>
                    <Col className="portfolio-gall-container">
                      <div className="portfolio-detail-desc">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit.
                      </div>
                    </Col>
                  </Row>

                </Container>
              </Col>
            </Row>
          </Container>
        </div>
      }
    </div>
  );
}

export default Portfolio;
