import { useState, useEffect, useRef } from 'react';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import { ParallaxProvider, useParallax, Parallax, ParallaxBanner } from 'react-scroll-parallax';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import "./styles.css";
import 'animate.css';
import './App.css';

import lupa from './img/lupa.png'
import app from './img/app.png'
import brand from './img/brand.png'
import plan from './img/plan.png'

function Services() {
  const host = 'https://inmobiplace.com/'

  return (
    <div>
      <div className='section-nav-space'></div>
      <div className="service-section">
        <ParallaxBanner
            layers={[
              {
                  image: host + 'services.png',
                  speed: 5,
                  style: {
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                  },
              },
            ]}
            style={{
              /*aspectRatio: '2 / 1',*/
            }}
        >
          <Container className="service-container">
            <Row className="align-items-center">
              <Col md={ 12 }>
                <div className="service-title">Servicios para proyectos residenciales, comerciales e industriales</div>
              </Col>
            </Row>
          </Container>
        </ParallaxBanner>
      </div>

      
          
      <div className="section-service">
        <Container className="section">
          <Row className="service-cards-container">
            <Col md={ 6 } className="col-md-6-custom">
              <Card body className="card-service">
                <div>
                  <img src={ lupa } className="service-icons" />
                </div>
                <div className="service-card-title">
                  INVESTIGACIÓN
                </div>
                <div className="service-card-desc">
                  Determinamos cuál es la mejor localidad para invertir, identificando los obstáculos, ventajas, crecimiento, tendencias y la competencia que exista dentro de la zona.
                </div>
                <div className="service-card-link">
                  Marketing geográfico
                </div>
              </Card>
            </Col>
            <Col md={ 6 } className="col-md-6-custom">
              <Card body className="card-service">
                <div>
                  <img src={ plan } className="service-icons" />
                </div>
                <div className="service-card-title">
                  PLANEACIÓN COMERCIAL, RESIDENCIAL O INDUSTRIAL
                </div>
                <div className="service-card-desc">
                  Desarrollamos las bases para comercializar tu inmueble, generando las estrategias apropiadas e identificando las oportunidades para impulsar el proyecto que se va a desarrollar.
                </div>
              </Card>
            </Col>
            <Col md={ 6 } className="col-md-6-custom">
              <Card body className="card-service">
                <div>
                  <img src={ brand } className="service-icons" />
                </div>
                <div className="service-card-title">
                  BRANDING
                </div>
                <div className="service-card-desc">
                  Empleamos nuestros 13 años de experiencia en marketing y publicidad, generando presencia y posicionamiento en el mercado inmobiliario.
                </div>
                <div className="service-card-link">
                  Creación de marca | Maquetación y  desarrollo de sitios web | Multimedia | Diseño gráfico | Renders
                </div>
              </Card>
            </Col>
            <Col md={ 6 } className="col-md-6-custom">
              <Card body className="card-service">
                <div>
                  <img src={ app } className="service-icons" />
                </div>
                <div className="service-card-title">
                  APLICACIONES DE MARKETING
                </div>
                <div className="service-card-desc">
                  Desarrollamos un plan a base de técnicas y estudios para alcanzar tus objetivos e impulsar tu proyecto.
                </div>
                <div className="service-card-link">
                  E-mail marketing | Social Media | Campañas en medios digitales | Campañas de ventas online | Atención online | Chat Bots | CRM | Apps | Masterplan Interactivos | Out of Home
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Services;
